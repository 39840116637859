@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

h1, h2, h3, h4, h5, h6, button, input, div, select, textarea, a, small, strong, span, section, article, label, *:not(i) {
  font-family: "Roboto", Tahoma, serif !important;
}

button, input, optgroup, select, textarea {
  font-family: "Roboto", Tahoma, serif !important;
}

html, body {
  overflow: hidden;
}

.ReactTable {
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
  border-radius: .28571429rem;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
  border-bottom: 1px solid #ddd;
  background: #f3f3f3;
}

.ReactTable .-pagination {
  box-shadow: none;
}

.jodit_progressbar div {
  height: 4px !important;
  background-color: orange !important;
}

.jodit_workplace {
}

.jodit-html-editor {
  background: #111;
  color: #d8d8d8;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 1rem;

  p {
    margin: 0 0 1em 0;
    font-weight: 400
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 1rem 0;
    font-weight: 600
  }

  ul {
    margin-top: 0
  }

  table {
    background: #141f29;
    -webkit-border-radius: .2em;
    border-radius: .2em;
    overflow: hidden
  }

  table, td, th {
    border: 1px solid #273747
  }

  table tr {
    border-color: #46637f
  }

  table td, table th {
    margin: .5em 1em;
    padding: .25em !important
  }

  table tr > td:first-child {
    white-space: nowrap
  }

  table td:before, table th {
    color: #dd5
  }

}

a {
  text-decoration: none;
  color: #607cc5;
}

svg[stroke="currentColor"] {
  font-size: inherit;
}

.MuiPickersCalendarHeader-transitionContainer {
  height: 50px !important;
}
